<template>
  <base-material-card>
    <v-col cols="12" align="center" v-if="$vuetify.breakpoint.smAndDown">
      <v-btn-toggle v-model="tab" mandatory>
        <v-btn
          height="30"
          :value="t.value"
          active-class="selected"
          outlined
          v-for="(t, i) in tabs"
          :key="i"
          >{{ t.text }}</v-btn
        >
      </v-btn-toggle>
    </v-col>
    <v-tabs v-model="tab" v-else>
      <v-row class="py-2">
        <v-tab v-for="(t, i) in tabs" :key="i" :href="`#${t.value}`">
          {{ t.text }}
        </v-tab>
        <v-col
          cols="6"
          sm="3"
          class="py-0"
          v-if="false"
          :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width: 170px'"
        >
          <v-select
            v-model="tab"
            :items="tabs"
            outlined
            dense
            hide-details
          ></v-select>
        </v-col>

        <v-col
          cols="6"
          sm="3"
          :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width: 170px'"
          class="py-0 ml-sm-5"
        >
          <v-menu
            v-model="menu.first_day"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                outlined
                dense
                :value="from ? $d(new Date(from), 'date2digits') : ''"
                hide-details
                readonly
                v-bind:class="{ 'error--text': from > to }"
                v-on="on"
                clearable
                class="pointer"
                :label="$tc('from')"
                @click:clear="
                  from = null;
                  load();
                "
              >
              </v-text-field>
            </template>
            <v-date-picker
              first-day-of-week="1"
              v-model="from"
              color="primary"
              no-title
              @input="
                menu.first_day = false;
                load();
              "
            ></v-date-picker>
          </v-menu>
        </v-col>
        <v-col
          cols="6"
          md="3"
          :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width: 170px'"
          class="py-0"
        >
          <v-menu
            v-model="menu.last_day"
            :close-on-content-click="false"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template v-slot:activator="{ on }">
              <v-text-field
                outlined
                dense
                :value="to ? $d(new Date(to), 'date2digits') : ''"
                hide-details
                readonly
                v-bind:class="{ 'error--text': from > to }"
                v-on="on"
                :label="$tc('to')"
                class="pointer"
                clearable
                @click:clear="
                  to = null;
                  load();
                "
              >
              </v-text-field>
            </template>
            <v-date-picker
              first-day-of-week="1"
              v-model="to"
              color="primary"
              no-title
              :min="from"
              @input="
                menu.last_day = false;
                load();
              "
            ></v-date-picker>
          </v-menu>
        </v-col>

        <v-col
          v-if="tab === 'tattooer'"
          cols="6"
          sm="3"
          class="py-0"
          :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width: 170px'"
        >
          <v-select
            outlined
            dense
            hide-details
            :items="states"
            v-model="state"
            @change="load()"
            clearable
            :label="$t('tattooers.state')"
          ></v-select>
        </v-col>
        <v-spacer></v-spacer>
        <v-btn height="30" elevation="0" @click="download" icon>
          <v-icon>$download</v-icon>
        </v-btn>
        <ExpensesForm
          :showAddButton="true"
          :expense="expense"
          :action="action"
          type="balance"
          ref="form"
          @update="load()"
          v-if="$store.getters['auth/isStudio']"
        />
      </v-row>
    </v-tabs>
    <v-row v-if="$vuetify.breakpoint.xsOnly">
      <v-col
        cols="6"
        sm="3"
        class="py-0"
        v-if="false"
        :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width: 170px'"
      >
        <v-select
          v-model="tab"
          :items="tabs"
          outlined
          dense
          hide-details
        ></v-select>
      </v-col>

      <v-col
        cols="6"
        sm="3"
        :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width: 170px'"
        class="py-0 ml-sm-5"
      >
        <v-menu
          v-model="menu.first_day"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              dense
              :value="from ? $d(new Date(from), 'date2digits') : ''"
              hide-details
              readonly
              v-bind:class="{ 'error--text': from > to }"
              v-on="on"
              clearable
              class="pointer"
              :label="$tc('from')"
              @click:clear="
                from = null;
                load();
              "
            >
            </v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="from"
            color="primary"
            no-title
            @input="
              menu.first_day = false;
              load();
            "
          ></v-date-picker>
        </v-menu>
      </v-col>
      <v-col
        cols="6"
        md="3"
        :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width: 170px'"
        class="py-0"
      >
        <v-menu
          v-model="menu.last_day"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{ on }">
            <v-text-field
              outlined
              dense
              :value="to ? $d(new Date(to), 'date2digits') : ''"
              hide-details
              readonly
              v-bind:class="{ 'error--text': from > to }"
              v-on="on"
              :label="$tc('to')"
              class="pointer"
              clearable
              @click:clear="
                to = null;
                load();
              "
            >
            </v-text-field>
          </template>
          <v-date-picker
            first-day-of-week="1"
            v-model="to"
            color="primary"
            no-title
            :min="from"
            @input="
              menu.last_day = false;
              load();
            "
          ></v-date-picker>
        </v-menu>
      </v-col>

      <v-col
        v-if="tab === 'tattooer'"
        cols="6"
        sm="3"
        class="py-0"
        :style="$vuetify.breakpoint.xsOnly ? '' : 'max-width: 170px'"
      >
        <v-select
          outlined
          dense
          hide-details
          :items="states"
          v-model="state"
          @change="load()"
          clearable
          :label="$t('tattooers.state')"
        ></v-select>
      </v-col>

      <v-spacer></v-spacer>

      <ExpensesForm
        :showAddButton="true"
        :expense="expense"
        :action="action"
        type="balance"
        ref="form"
        @update="load()"
      />
    </v-row>

    <v-tabs-items v-model="tab">
      <v-tab-item value="tattooer" v-if="$store.getters['auth/isStudio']">
        <base-data-table-cards
          :headers="headers"
          :headers_small="headers_small"
          :data="items"
          id="commissions-table"
          :title="deuda"
        >
          <template slot="body_prepend">
            <base-list-card
              v-if="$vuetify.breakpoint.smAndDown"
              :headers="headers"
              :headers_small="headers_small"
              :item="{}"
              title="TOTAL"
            >
              <template v-slot:item_name>
                <span class="primary--text" style="font-size: 1rem">Total</span>
              </template>
              <template v-slot:item_pending>
                {{ $n(pendings, "currency") }}
              </template>
              <template v-slot:item_total>
                {{ $n(total_facturation, "currency") }}
              </template>
              <template v-slot:item_clean>
                {{ $n(clean, "currency") }}
              </template>
              <template v-slot:item_clean_artist>
                {{ $n(clean_artist, "currency") }}
              </template>

              <template v-slot:item_status="{}">
                <v-chip class="mr-3" color="success" v-if="pendings === 0">{{
                  $t("ok")
                }}</v-chip>
                <v-chip class="mr-3" color="red" v-else-if="pendings > 0">{{
                  $t("expenses.p_pay")
                }}</v-chip>
                <v-chip class="mr-3" color="red" v-else>{{
                  $t("expenses.p_cobrar")
                }}</v-chip>
              </template>
            </base-list-card>
            <tr v-else class="total">
              <th>
                {{ $t("refunds.types.total") }}
              </th>
              <th>
                {{ $n(total_facturation, "currency") }}
              </th>
              <th>
                {{ $n(clean, "currency") }}
              </th>
              <th>
                {{ $n(clean_artist, "currency") }}
              </th>
              <th>
                {{ $n(pendings, "currency") }}
              </th>
              <th>
                <v-chip color="success" v-if="pendings === 0">{{
                  $t("ok")
                }}</v-chip>
                <v-chip color="red" v-else-if="pendings > 0">{{
                  $t("expenses.p_pay")
                }}</v-chip>
                <v-chip color="red" v-else>{{
                  $t("expenses.p_cobrar")
                }}</v-chip>
              </th>
              <th></th>
            </tr>
          </template>
          <template v-slot:item_name="item">
            {{ item.user.first_name }}
            {{ item.user.last_name || "" }}
          </template>

          <template v-slot:item_pending="item">
            {{ item.pending ? $n(item.pending, "currency") : "-" }}
          </template>
          <template v-slot:item_total="item">
            {{
              item.facturationTotal > 0
                ? $n(item.facturationTotal, "currency")
                : "-"
            }}
          </template>
          <template v-slot:item_clean="item">
            {{
              item.facturationStudio
                ? $n(item.facturationStudio, "currency")
                : "-"
            }}
          </template>
          <template v-slot:item_clean_artist="item">
            {{ item.commission ? $n(item.commission, "currency") : "-" }}
          </template>
          <template v-slot:item_status="item">
            <v-chip
              class="mr-3 mr-md-0"
              color="success"
              v-if="item.pending === 0"
              >{{ $t("ok") }}</v-chip
            >
            <v-chip
              class="mr-3 mr-md-0"
              color="red"
              v-else-if="item.pending > 0"
              >{{ $t("expenses.p_pay") }}</v-chip
            >
            <v-chip class="mr-3 mr-md-0" color="red" v-else>{{
              $t("expenses.p_cobrar")
            }}</v-chip>
          </template>
          <template v-slot:item_actions="item">
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-icon large v-on="on">mdi-dots-horizontal</v-icon>
              </template>
              <v-list>
                <v-list-item
                  class="lessMargin"
                  @click="
                    $router.push({
                      name: 'tattooerView',
                      params: { id: item.id },
                    })
                  "
                >
                  <v-list-item-icon>
                    <v-icon small style="margin-top: -4px !important">
                      $eye
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("view", { name: $tc("artist") }) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
                <v-dialog v-model="comisions" width="570">
                  <template v-slot:activator="{ on }">
                    <v-list-item
                      class="lessMargin"
                      v-on="on"
                      v-if="item.commissions !== 0"
                    >
                      <v-list-item-icon style="margin-top: 10px">
                        <v-icon small>mdi-check-underline</v-icon>
                      </v-list-item-icon>
                      <v-list-item-title>
                        <span>{{
                          $t("economys.forecast.liquidate_commission")
                        }}</span>
                      </v-list-item-title>
                    </v-list-item>
                  </template>
                  <v-card>
                    <v-card-title>
                      {{ $t("economys.forecast.liquidate_commission") }}
                    </v-card-title>
                    <div class="close">
                      <v-btn icon @click="comisions = false">
                        <v-icon id="iconClose">mdi-close</v-icon>
                      </v-btn>
                    </div>
                    <v-card-text>
                      <v-row>
                        <v-col>
                          <v-card
                            @click="
                              confirmCommission(item);
                              comisions = false;
                            "
                            style="border: 1px solid var(--v-primary-base)"
                          >
                            <v-card-title
                              style="
                                font-size: 18px !important;
                                text-decoration: none !important;
                              "
                              class="py-6"
                            >
                              TOTAL
                            </v-card-title>
                          </v-card>
                        </v-col>
                        <v-col>
                          <v-card
                            @click="
                              $refs[`liquidation-${item.id}`].open();
                              comisions = false;
                            "
                            style="border: 1px solid var(--v-primary-base)"
                          >
                            <v-card-title
                              class="py-6"
                              style="
                                font-size: 18px !important;
                                text-decoration: none !important;
                              "
                              >PARCIAL</v-card-title
                            >
                          </v-card>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-dialog>

                <v-list-item class="lessMargin" @click="openForm(item)">
                  <v-list-item-icon style="margin-top: 10px">
                    <v-icon small>mdi-plus</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title>
                    <span>{{ $t("economys.add_balance") }}</span>
                  </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
            <ExpensesForm
              :showAddButton="false"
              :expense="expense"
              :action="action"
              type="balance-liquidation"
              :ref="`liquidation-${item.id}`"
              :tattooerId="item.id"
              :max="item.commissions"
              @update="load()"
            />
          </template>
        </base-data-table-cards>
      </v-tab-item>
      <v-tab-item value="customer" v-if="$store.getters['auth/isStudio']">
        <base-data-table-cards
          :headers="headers_customers"
          :headers_small="headers_small"
          :data="items_customers"
          id="commissions-customers-table"
          title="deuda"
        >
          <template v-slot:body_prepend>
            <base-list-card
              v-if="$vuetify.breakpoint.smAndDown"
              :headers="headers_customers"
              :headers_small="headers_small"
              :item="{}"
              title="TOTAL"
            >
              <template v-slot:item_name>
                <span class="primary--text" style="font-size: 1rem">Total</span>
              </template>
              <template v-slot:item_pending>
                {{ $n(pendings_customers, "currency") }}
              </template>
              <template v-slot:item_total>
                {{ $n(total_facturation_customers, "currency") }}
              </template>
            </base-list-card>
            <tr v-else class="total">
              <th>
                {{ $t("refunds.types.total") }}
              </th>
              <th>
                {{ $n(total_facturation_customers, "currency") }}
              </th>
              <th>
                {{ $n(pendings_customers, "currency") }}
              </th>
              <th></th>
            </tr>
          </template>
          <template v-slot:item_name="item">
            {{ item.user.first_name || "-" }}
            {{ item.user.last_name || "" }}
          </template>

          <template v-slot:item_total="item">
            {{ $n(item.total, "currency") }}
          </template>
          <template v-slot:item_pending="item">
            {{ $n(item.pending, "currency") }}
          </template>

          <template v-slot:item_actions="item">
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-icon large v-on="on">mdi-dots-horizontal</v-icon>
              </template>
              <v-list>
                <v-list-item
                  class="lessMargin"
                  @click="
                    $router.push({
                      name: 'customerView',
                      params: { id: item.id },
                    })
                  "
                >
                  <v-list-item-icon>
                    <v-icon small style="margin-top: -4px !important">
                      $eye
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("view", { name: $tc("customer") }) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </base-data-table-cards>
      </v-tab-item>

      <v-tab-item value="studio">
        <base-data-table-cards
          :headers="headers_studio"
          :headers_small="headers_small"
          :data="items_studio"
          id="commissions-studio-table"
          title="Deuda"
        >
          <template v-slot:body_prepend>
            <base-list-card
              v-if="$vuetify.breakpoint.smAndDown"
              :headers="headers_studio"
              :headers_small="headers_small"
              :item="{}"
              title="TOTAL"
            >
              <template v-slot:item_name>
                <span class="primary--text" style="font-size: 1rem">Total</span>
              </template>
              <template v-slot:item_pending>
                {{ $n(pendings_studio, "currency") }}
              </template>
              <template v-slot:item_total>
                {{ $n(total_facturation_studio, "currency") }}
              </template>
              <template v-slot:item_clean>
                {{ $n(clean_studio, "currency") }}
              </template>
              <template v-slot:item_clean_artist>
                {{ $n(clean_artist_studio, "currency") }}
              </template>
            </base-list-card>
            <tr v-else class="total">
              <th>
                <div style="width: 100%">
                  {{ $t("refunds.types.total") }}
                </div>
              </th>
              <th>
                {{ $n(total_facturation_studio, "currency") }}
              </th>

              <th>
                {{ $n(clean_studio, "currency") }}
              </th>
              <th>
                {{ $n(clean_artist_studio, "currency") }}
              </th>

              <th>
                {{ $n(pendings_studio, "currency") }}
              </th>
              <th></th>
            </tr>
          </template>
          <template v-slot:item_name="item">
            {{ item.studio_name }}
          </template>

          <template v-slot:item_pending="item">
            {{ item.pending ? $n(item.pending, "currency") : "-" }}
          </template>
          <template v-slot:item_total="item">
            {{
              item.facturationTotal > 0
                ? $n(item.facturationTotal, "currency")
                : "-"
            }}
          </template>
          <template v-slot:item_clean="item">
            {{
              item.facturationStudio
                ? $n(item.facturationStudio, "currency")
                : "-"
            }}
          </template>
          <template v-slot:item_clean_artist="item">
            {{ item.commission ? $n(item.commission, "currency") : "-" }}
          </template>
          <template v-slot:item_actions="item">
            <v-menu>
              <template v-slot:activator="{ on }">
                <v-icon large v-on="on">mdi-dots-horizontal</v-icon>
              </template>
              <v-list>
                <v-list-item
                  class="lessMargin"
                  @click="
                    $router.push({
                      name: 'studioView',
                      params: { id: item.id },
                    })
                  "
                >
                  <v-list-item-icon>
                    <v-icon small style="margin-top: -4px !important">
                      $eye
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ $t("view", { name: $tc("studio") }) }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
            </v-menu>
          </template>
        </base-data-table-cards>
      </v-tab-item>
    </v-tabs-items>
  </base-material-card>
</template>

<script>
import { mapActions, mapState } from "vuex";
import excelGenerator from "@/mixins/excelGenerator";
export default {
  name: "Commisions",
  mixins: [excelGenerator],
  components: {
    ExpensesForm: () => import("@/components/expense/Form"),
  },
  data() {
    return {
      expense: {},
      comisions: false,
      tab: "tattooer",

      menu: {
        first_day: false,
        last_day: false,
      },
      states: [
        { text: this.$t("ok"), value: "ok" },
        { text: this.$t("expenses.p_pay"), value: "p_pay" },
        { text: this.$t("expenses.p_cobrar"), value: "p_cobrar" },
      ],
      state: null,
      action: "save",
      items: [],
      options: {},
      headers: [
        {
          text: this.$tc("artist"),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: this.$tc("expenses.total_facturation"),
          align: "center",
          sortable: false,
          value: "total",
        },
        {
          text: this.$tc("expenses.clean"),
          align: "center",
          sortable: false,
          value: "clean",
        },

        {
          text: this.$tc("expenses.clean_artist"),
          align: "center",
          sortable: false,
          value: "clean_artist",
        },
        {
          text: this.$tc("expenses.pendings"),
          align: "center",
          sortable: false,
          value: "pending",
        },

        {
          text: this.$tc("tattooers.state"),
          align: "center",
          sortable: false,
          value: "status",
        },
        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      headers_small: [
        {
          text: this.$tc("artist"),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: this.$tc("tattooers.state"),
          align: "center",
          sortable: false,
          value: "status",
          no_col: true,
        },
      ],

      items_customers: [],
      options_customers: {},
      headers_customers: [
        {
          text: this.$tc("customer"),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: this.$tc("expenses.total_facturation"),
          align: "center",
          sortable: false,
          value: "total",
        },
        {
          text: this.$tc("expenses.pendings"),
          align: "center",
          sortable: false,
          value: "pending",
        },

        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
      items_studio: [],
      options_studio: {},
      headers_studio: [
        {
          text: this.$tc("studio"),
          align: "center",
          sortable: false,
          value: "name",
        },
        {
          text: this.$tc("expenses.total_facturation"),
          align: "center",
          sortable: false,
          value: "total",
        },
        {
          text: this.$tc("expenses.clean"),
          align: "center",
          sortable: false,
          value: "clean",
        },

        {
          text: this.$tc("expenses.clean_artist"),
          align: "center",
          sortable: false,
          value: "clean_artist",
        },
        {
          text: this.$tc("expenses.pendings"),
          align: "center",
          sortable: false,
          value: "pending",
        },

        {
          text: this.$t("actions"),
          align: "center",
          sortable: false,
          value: "actions",
        },
      ],
    };
  },
  mounted() {
    this.load();
  },
  methods: {
    download() {
      switch (this.tab) {
        case "tattooer":
          this.exportToExcel(
            this.items,
            [
              { value: "user.fullname", text: "Artista" },
              { value: "facturationTotal", text: "Facturacion Total" },
              { value: "facturationStudio", text: "Neto Estudio" },
              { value: "commission", text: "Neto Artista" },
              { value: "pending", text: "Deuda" },
            ],
            "Deudas"
          );
          break;
        case "studio":
          this.exportToExcel(
            this.items_studio,
            [
              { value: "user.fullname", text: "Artista" },
              { value: "facturationTotal", text: "Facturacion Total" },
              { value: "facturationStudio", text: "Neto Estudio" },
              { value: "commission", text: "Neto Artista" },
              { value: "pending", text: "Deuda" },
            ],
            "Deudas"
          );
          break;
        case "customer":
          this.exportToExcel(
            this.items_customers,
            [
              { value: "user.fullname", text: "Artista" },
              { value: "total", text: "Facturacion Total" },
              { value: "pending", text: "Deuda" },
            ],
            "Deudas"
          );
          break;
      }
    },
    ...mapActions("expenses", [
      "getExpensesPayed",
      "getExpensesCustomerPayed",
      "getExpensesStudioPayed",
      "confirmExpenseCommisionTattooer",
    ]),
    load() {
      this.fetchExpense();
      this.fetchExpenseCustomer();
      this.fetchExpenseStudio();
    },
    fetchExpense() {
      this.getExpensesPayed({
        pagination: this.options,
        filters: {
          to: this.to,
          from: this.from,
          state: this.state,
        },
      }).then((result) => {
        this.items = result;
      });
    },
    fetchExpenseCustomer() {
      this.getExpensesCustomerPayed({
        pagination: this.options_customers,
        filters: {
          to: this.to,
          from: this.from,
          state: this.state,
        },
      }).then((result) => {
        this.items_customers = result;
      });
    },

    fetchExpenseStudio() {
      this.getExpensesStudioPayed({
        pagination: this.options_customers,
        filters: {
          to: this.to,
          from: this.from,
          state: this.state,
        },
      }).then((result) => {
        this.items_studio = result;
      });
    },
    confirmCommission(item) {
      this.$confirm(this.$t("liquidate_commisions")).then(() => {
        this.confirmExpenseCommisionTattooer(item.id).then(() => {
          this.$alert(this.$t("save_ok"), "", "");

          this.load();
        });
      });
    },
    openForm(item) {
      this.$refs.form.open();
      this.expense.tattooer_id = item.id;
    },
  },
  computed: {
    tabs() {
      if (this.$store.getters["auth/isStudio"])
        return [
          { text: this.$tc("artist"), value: "tattooer" },
          { text: this.$tc("customer"), value: "customer" },
        ];
      return [{ text: this.$tc("studio"), value: "studio" }];
    },
    ...mapState("expenses", ["filtersState"]),
    from: {
      get() {
        return this.filtersState.from;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_FROM", value);
      },
    },
    to: {
      get() {
        return this.filtersState.to;
      },
      set(value) {
        this.$store.commit("expenses/SET_EXPENSES_TO", value);
      },
    },
    total_facturation() {
      let total = 0;
      this.items.forEach((t) => {
        total += t.facturationTotal;
      });
      return total;
    },
    total_facturation_customers() {
      let total = 0;
      this.items_customers.forEach((t) => {
        total += t.total;
      });
      return total;
    },

    total_facturation_studio() {
      let total = 0;
      this.items_studio.forEach((t) => {
        total += t.facturationTotal;
      });
      return total;
    },
    clean() {
      let total = 0;
      this.items.forEach((t) => {
        total += t.facturationStudio;
      });
      return total;
    },
    clean_artist() {
      let total = 0;
      this.items.forEach((t) => {
        total += t.commission;
      });
      return total;
    },

    clean_studio() {
      let total = 0;
      this.items_studio.forEach((t) => {
        total += t.facturationStudio;
      });
      return total;
    },
    clean_artist_studio() {
      let total = 0;
      this.items_studio.forEach((t) => {
        total += t.commission;
      });
      return total;
    },
    pendings() {
      let total = 0;
      this.items.forEach((t) => {
        total += t.pending;
      });
      return total;
    },
    pendings_customers() {
      let total = 0;
      this.items_customers.forEach((t) => {
        total += t.pending;
      });
      return total;
    },

    pendings_studio() {
      let total = 0;
      this.items_studio.forEach((t) => {
        total += t.pending;
      });
      return total;
    },
  },
};
</script>

<style scoped lang="sass">
#commissions-table,
#commissions-customers-table,
#commissions-studio-table
  .total
    th
      font-size: 1rem
      color: var(--v-primary-base) !important
</style>
